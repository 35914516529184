@font-face {
  font-family: 'KosugiMaru';
  font-style: normal;
  font-weight: normal;
  src: url('/static/fonts/family/KosugiMaru-Regular.ttf') format('truetype');
}
.base-theme {
  font-family: "KosugiMaru", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  font-size: 16px;
  color: var(--theme-text-color);
}
.base-main {
  margin-bottom: 64px;
}
.base-inner {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 32px;
}
.base-headline {
  margin-top: calc(48px + 16px);
  margin-bottom: 48px;
}
.base-headline p {
  font-family: 'Raleway' , "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.1em;
  text-align: center;
}
.base-column {
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.base-column__main {
  width: calc(100% - 320px - 32px);
}
.base-column__main__section + .base-column__main__section {
  margin-top: 64px;
}
.base-column__ttl {
  width: 100%;
  padding-bottom: 16px;
  margin-bottom: 32px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
.base-column__ttl::after {
  content: "";
  box-sizing: border-box;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background: var(--theme-primary-color);
  position: absolute;
  left: 0;
  bottom: 0;
}
.base-column__ttl__ic {
  width: 48px;
  height: 48px;
  border-radius: 100%;
  border: 8px solid var(--theme-primary-color);
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.base-column__ttl__ic::after {
  content: "";
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background: var(--theme-primary-color);
}
.base-column__ttl__body {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.base-column__ttl__body__main {
  font-size: 24px;
  font-weight: bold;
  line-height: 1;
}
.base-column__ttl__body__main h2 {
  font-size: 32px;
  display: inline;
  color: var(--theme-subheader-color);
  background-image: linear-gradient(transparent 60%, var(--theme-secondary-color) 0%);
}
.base-column__ttl__body__sub {
  font-size: 16px;
  margin-left: auto;
}
.base-column__calendar {
  width: 320px;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 4px 8px rgba(60, 64, 67, 0.1);
  position: sticky;
  top: 16px;
}
.base-column__spFixed { display: none; }
@media screen and (max-width:768px) {
  .base-inner {
    padding: 0 16px;
  }
  .base-headline {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  .base-singleTop {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
  .base-column {
    margin-top: 16px;
    display: block;
  }
  .base-column__main {
    width: 100%;
  }
  .base-column__main__section + .base-column__main__section {
    margin-top: 32px;
  }
  .base-column__ttl {
    margin-bottom: 16px;
  }
  .base-column__ttl__body {
    display: block;
  }
  .base-column__ttl__body__main {
    
  }
  .base-column__ttl__body__main h2 {
    font-size: 24px;
  }
  .base-column__calendar {
    width: 100%;
    position: relative;
    top: 0;
    margin: 32px auto 0 auto;
  }
  .base-column__spFixed {
    width: 100%;
    height: 96px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    bottom: 0;
    z-index: 50;
  }
  .base-column__spFixed::before {
    content: "";
    width: 100%;
    height: 50%;
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,var(--theme-background-color) 100%);
    position: absolute;
    top: 0;
    left: 0;
  }
  .base-column__spFixed::after {
    content: "";
    width: 100%;
    height: 50%;
    background: var(--theme-background-color);
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .base-column__spFixed__btn {
    width: 280px;
    height: 48px;
    font-size: 18px;
    font-weight: bold;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    color: #fff;
    border-radius: 24px;
    background: var(--theme-primary-color);
  }
}


/*------------------------------------------------------------------------------
utility
------------------------------------------------------------------------------*/
.base-padding__8-8 { padding: 8px; }
.base-padding__8-16 { padding: 8px 16px; }
.base-padding__16-8 { padding: 16px 8px; }
.base-padding-16 { padding: 16px; }
.base-padding-16-32 { padding: 16px 32px; }
.base-padding-32-16 { padding: 32 16px; }

.base-marginTop-8 { margin-top: 8px; }
.base-marginTop-16 { margin-top: 16px; }
.base-marginTop-32 { margin-top: 32px; }

.base-marginBottom-8 { margin-bottom: 8px; }
.base-marginBottom-16 { margin-bottom: 16px; }
.base-marginBottom-32 { margin-bottom: 32px; }



